/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { IntlProvider } from 'react-intl'
import clsx from 'clsx'

import CountryProvider from '../../contexts/CountryContext'
import CartProvider from '../../contexts/CartContext'
import IdentityProvider from '../../contexts/IdentityContext'
import translations from '../../translations'

import Header from '../Header'
import Footer from '../Footer'
import Drawer from '../Drawer'
import { ThemesProvider } from '@happy-rabbit/gatsby-sanity-cms'
import { ShopifyProvider } from '../../utils/shopify-api/shopifyContext'

const Layout = (props) => {
  const {
    children,
    enableRunRoutes = false,
    lang = 'en',
    pageDesign = null,
  } = props

  const { startContentAt = 'below-header', themeReferences: themes } =
    pageDesign || {}

  return (
    <IdentityProvider enableRunRoutes={enableRunRoutes}>
      <CountryProvider>
        <ShopifyProvider>
          <CartProvider>
            <IntlProvider
              messages={translations[lang]}
              locale={lang}
              defaultLocale="en"
            >
              <ThemesProvider themes={themes}>
                <Drawer>
                  <div className="flex flex-col min-h-screen justify-between">
                    <Header />

                    <main
                      className={clsx('mb-auto', {
                        'pt-24 lg:pt-32': startContentAt !== 'top-of-window',
                      })}
                    >
                      {children}
                    </main>

                    <Footer />
                  </div>
                </Drawer>
              </ThemesProvider>
            </IntlProvider>
          </CartProvider>
        </ShopifyProvider>
      </CountryProvider>
    </IdentityProvider>
  )
}

export default Layout
