import { navigate } from 'gatsby-link'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCMSContext } from '@happy-rabbit/gatsby-sanity-cms'
import { Button } from '@happy-rabbit/component-library'

import { useDrawerStateContext } from '../../contexts/DrawerContext'
import CartLineItems from './CartLineItems'
import CartTotals from './CartTotals'
import { useShopify } from '../../utils/shopify-api/shopifyContext'

const ShoppingCart = () => {
  const { cart, handleGoToCheckout, formattedLineItems } = useShopify()
  const { lang, site } = useCMSContext()
  const { close } = useDrawerStateContext()

  // Transform cart lines into lineItems format

  const lineItems = useMemo(() => {
    if (!cart?.lines?.edges) return []
    return cart.lines.edges.map(({ node }) => ({
      id: node.id,
      quantity: node.quantity,
      variant: {
        id: node.merchandise.id,
        sku: node.merchandise.sku, // Extract SKU from Shopify Global ID
        title: node.merchandise.title,
        price: node.merchandise.price,
        product: node.merchandise.product,
        selectedOptions: node.merchandise.selectedOptions,
        image: {
          src: node.merchandise.image?.url || '',
          alt:
            node.merchandise.image?.altText || node.merchandise.product.title,
        },
      },
      title: node.merchandise.product.title,
    }))
  }, [cart?.lines])

  const handleCheckoutClick = useCallback(() => {
    try {
      // Google Analytics tracking
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(function () {
        this.reset()
      })
      window.dataLayer.push({
        event: 'eec.checkout',
        ecommerce: {
          currencyCode: cart?.estimatedCost?.totalAmount?.currencyCode,
          checkout: {
            actionField: {
              step: 1,
            },
            products: lineItems.map((lineItem) => ({
              id: lineItem.variant.sku,
              name: `${lineItem.title} / ${lineItem.variant.title}`,
              quantity: lineItem.quantity,

              dimension2: lineItem.variant.selectedOptions?.[0]?.value || '',
              dimension3: lineItem.variant.selectedOptions?.[1]?.value || '',
            })),
          },
        },
      })

      // Use the new goToCheckout function from Shopify context
      const prefix =
        site._id === 'global' ? '' : `/${site.slug.current}/${lang}`
      navigate(`${prefix}/checkout`)
    } catch (e) {
      console.error('Error during checkout:', e)
    }
  }, [cart, lineItems, handleGoToCheckout])

  return (
    <div className="h-full flex flex-col flex-stretch">
      <div className="h-16 flex-initial px-6">
        <h2 className="text-center text-2xl">
          <FormattedMessage
            id="shoppingCart.your-shopping-bag"
            defaultMessage="Your shopping bag"
          />
        </h2>
      </div>

      <CartLineItems lineItems={lineItems} />

      <div className="flex-initial flex flex-col">
        <div className="mx-6">
          <CartTotals isShoppingBag={true} />
        </div>

        <div className="flex w-full h-12">
          <div className="w-1/2">
            <Button
              disabled={!cart || cart?.lines?.edges?.length === 0}
              fullWidth={true}
              height={'full'}
              onClick={close}
              theme="ghost"
            >
              <FormattedMessage
                id="shoppingCart.continue-shopping"
                defaultMessage="Continue Shopping"
              />
            </Button>
          </div>

          <div className="w-1/2">
            <Button
              disabled={!cart || cart?.lines?.edges?.length === 0}
              fullWidth={true}
              height={'full'}
              onClick={handleCheckoutClick}
              theme="success"
            >
              <FormattedMessage
                id="shoppingCart.checkout"
                defaultMessage="Checkout"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCart
